<template>
  <q-dialog full-width
            transition-show="slide-down"
            transition-hide="slide-up"
            v-model="showing">
    <q-card class="about-us">
      <q-toolbar dark class="bg-grey-9">
        <q-btn-group outline>
          <q-btn dark
                 outline
                 label="About Us"
                 :color="tab === 'about' ? 'red' : 'white'"
                 @click="tab = 'about'"/>
          <q-btn dark outline
                 label="Project Collaborators"
                 :color="tab === 'collaborators' ? 'red' : 'white'"
                 @click="tab = 'collaborators'"/>
          <q-btn dark outline
                 label="Advisory Board"
                 :color="tab === 'advisory' ? 'red' : 'white'"
                 @click="tab = 'advisory'"/>
        </q-btn-group>

        <q-space />

        <q-btn dark
               flat
               round
               dense
               icon="close"
               text-color="white"
               v-close-popup />
      </q-toolbar>

      <q-tab-panels animated
                    class="about-us__tab-panels q-py-md bg-grey-1"
                    v-model="tab">
        <q-tab-panel name="about" class="q-px-md">
          <q-scroll-area visible style="height: 85vh;">
            <h2 class="text-grey-9">How to Use "Reading Like a Victorian"</h2>
            <p>
              RLV is an interactive timeline of the Victorian period. It focuses on serialized novels (the longer entries on the timeline) and adds volume-format publications for context. To access the timeline, click on the Launch button or scroll down from the landing page. The colors on the timeline do not have any significance; they are there to make the timeline easier to read.
            </p>
            <p>
              Use the right-left arrow keys at the top of the timeline or on your keyboard to scroll through the years of the Victorian period. You can use the Jump to Date function to find a year and month more quickly. If you do not know the month of publication, click on January and scroll right to find the title you want.
            </p>
            <p>
              Click on a title to get access to the full text online. Scroll down through the pop-up box to see links to all the installments of a serialized novel. Click on a Chapter to go to an external source for the text. Librivox is an audiobook and Project Gutenberg a typed-out version. Hathi Trust, Project Boz, and Internet Archive provide facsimiles of the original publication. (Links to journals on Hathi Trust change often, so you may need to navigate to the issue’s table of contents to find the chapters you want. Project Gutenberg sometimes lands on page 1 of the novel before jumping to the chapter you are seeking, but it will get there if you wait a moment.)
            </p>
            <p>
              Close the pop-up box to return to the timeline. You can close it by clicking anywhere on the timeline outside the pop-up box.
            </p>
            <p>
              Use the Filters to search by authors’ gender identity, genre, or language. Scroll right and left through the timeline to see search results. Genre filters are cumulative; if you click more than one category, the timeline will show titles in all of those genres.
            </p>
            <p>
              To use Author Search, scroll down or type in the author’s name, then click on the name to see titles that are on the timeline. Click on a title to jump to it on the timeline. Scroll down from the landing page or use the Launch button to see the pop-up box for that title.
            </p>
            <p>
              We are continually adding more titles to the timeline. Titles on the timeline as of Spring 2021 have been requested by users. Our goal is to fill in each year of Victoria’s reign, from
1837-1901. In the meantime, we welcome requests.
            </p>

            <q-separator color="grey-5" />

            <h2 class="text-grey-9">Binge reading vs. synchronic reading</h2>
            <p>
              When we read Victorian novels today, we do not read them
              in the form in which they originally came out. Most Victorian
              novels appeared either as “triple deckers,” three volumes
              released at one time, or as serials published monthly or weekly
              in periodicals or in pamphlet form. Serialized novels’ regularly
              timed, intermittent appearance made for a reading experience
              resembling what we do when we are awaiting the next weekly
              episode of Game of Thrones, watching installments of other TV
              serials in the meantime. Whenever we pick up a Penguin or
              Oxford paperback of a Victorian novel today, we are engaged
              in the equivalent of binge-watching a series that has already
              reached its broadcast ending. To read Great Expectations or
              Middlemarch —- that is, to “binge” it —- from cover to cover,
              and to finish it before picking up another novel, is to have
              a very different experience from what Victorian audiences were
              doing with novels. Reading Like a Victorian reproduces the
              “serial moment” experienced by Victorian readers, providing
              users online access to any given month and year during the
              nineteenth century when up-to-date readers of fiction would
              have been following installments of multiple serials and works
              published in volume form that overlapped in real time.
              Reading synchronically gives new insight into the themes and
              forms of the Victorian novel.
            </p>
            <p>
              A 21st-century reader of Victorian novels has had few
              opportunities to read the parts of 19th-century novels
              with and against other texts that appeared at the same time.
              J. Don Vann’s Victorian Novels in Serial has long been available
              as a guide to the dates of serial installments, though the
              logistics of aligning installments of printed novels with those
              that appeared simultaneously have been difficult. But if reading
              serial installments at their original pace is valuable, it is
              even more valuable to read them alongside parts of novels and
              of other kinds of texts that Victorian readers could have been
              following at the same time. Patterns of theme and ideology,
              as well as conventions of the serial genre, emerge even more
              vividly through this experience than through the serial reading
              of a single text.
            </p>
            <p>
              To give just one example from an earlier stack of novels,
              a reader who, in 1847, had been following the part issues
              of both Dickens’s Dombey and Son and Thackeray’s Vanity Fair
              and then picked up Jane Eyre, published in volume form in
              October of that year, might notice in Florence Dombey,
              Becky Sharp, and Jane Eyre a pattern of motherless or orphaned
              girls trying to negotiate a hostile world on their own.
              While this figure is well known to be a character type in
              Victorian fiction perfectly embodied by Jane Eyre and
              Florence Dombey, Becky Sharp does not often emerge among the
              heroines who fit that type; reading the novels simultaneously
              foregrounds parallels between Becky, Florence, and Jane that
              are not at all obvious if their storylines are experienced
              separately. Read synchronically, the novels seem to answer
              and comment upon one another. Indeed, there is plenty of
              evidence that novelists were reading one another’s serials
              while composing their own and were actively competing for the
              attention of real-time readers. While in the process of composing
              the serial parts of Vanity Fair, Thackeray famously slammed the
              most recent installment of Dombey down on the desk of his
              publisher and exclaimed, “There’s no writing against such power
              as this—one has no chance! Read that chapter describing young
              Paul’s death; it is unsurpassed—it is stupendous!”
              Scholars have traced detailed conversations between novelists
              conducted entirely through serial parts that implicitly
              respond to each other, but for our purposes, unintentional
              correspondences among texts are as interesting as novelists’
              direct citations of one another’s work.
            </p>

            <q-separator color="grey-5" />

            <h2 class="text-grey-9">History of the Project</h2>
            <p>The Reading Like a Victorian website was inspired by two
              graduate seminars focusing on synchronic reading, Robyn Warhol’s
              “Reading Victorian Novels Serially” at The Ohio State University
              and Helena Michie’s “On or About 1860” at Rice University.
              Helena’s class, which she has offered for many years, reads
              British novels, poetry, political and scientific writings from
              the year 1860 in order to get a deep sense of the conversations
              and concerns of the moment. Robyn’s class studied two “stacks” of
              serialized Victorian novels, one from 1847-49 and the other from
              1864-66. The students read all the installments of various novel
              that came out in the same month and year, then moved on to the
              next month, and so forth, to recreate the historical experience
              of holding multiple stories suspended in memory while waiting
              for the next installment to appear.
            </p>
            <p>
              Because the logistics of teaching these courses with paper texts
              were prohibitively complicated, Robyn set up a WordPress site at
              <a href="https://victorianserialnovels.org">victorianserialnovels.org</a>,
              “Reading Like a Victorian” 1.0, funded by a Textbook Affordability
              Grant from OSU. The present site is an updated version made
              possible by funding from the Battelle Institute Engineering,
              Technology, and Human Affairs Endowment and time donated by OSU’s
              College of Arts and Sciences Technology Services.
            </p>

            <q-separator color="grey-5" />

            <h2 class="text-grey-9">Disability Statement</h2>
            <p>
              If you have difficulty accessing the Reading Like a Victorian
              website a <a href="/dataset" download>full CSV dataset download</a>
              is available or send an email to Prof. Robyn Warhol
              (<a href="mailto:readinglikeavictorian@gmail.com">readinglikeavictorian@gmail.com</a>),
              the project lead, to request the data available on this web site
              in an alternate format.
            </p>

            <q-separator color="grey-5" />

            <h2 class="text-grey-9">Feedback</h2>
            <p>
              If you would like to offer feedback, report an error, or request a
              text or author be added to our project, please feel free to contact
              us at <a href="mailto:readinglikeavictorian@gmail.com">readinglikeavictorian@gmail.com</a>.
            </p>
          </q-scroll-area>
        </q-tab-panel>

        <q-tab-panel name="collaborators">
          <q-scroll-area visible style="height: 85vh;">
            <q-list padding>
              <q-item>
                <q-item-section>
                  <q-item-label>Robyn Warhol</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Investigator, Arts & Sciences Distinguished
                      Professor of English, Project Narrative Core
                      Faculty Member, The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Creator of “Reading Like a Victorian” at
                    <a href="victorianserialnovels.org">victorianserialnovels.org</a>,
                    Warhol is a specialist in narrative theory, gender theory,
                    popular culture (TV and comics) and Victorian literature.
                    For decades she has worked on serial form in TV and novels.
                    Examples include Having a Good Cry: Effeminate Tears and
                    Popular-Culture Forms (Ohio State UP, 2003) and
                    “Binge Watching: How Netflix Original Programs Are
                    Changing Serial Form.” Literatur in Wissenschaft und
                    Unterricht, 47.1/2 (2014): 145-158. Her most recent
                    publications include two award-winning books, Narrative
                    Theory Unbound: Queer and Feminist Interventions, co-edited
                    by Susan S. Lanser, (Ohio State UP 2015), Honorable Mention
                    for the Perkins Prize, International Society for the Study
                    of Narrative; and Love Among the Archives: Writing the Lives
                    of George Scharf, Victorian Bachelor, co-authored by Helena
                    Michie, (Edinburgh University Press, 2015), winner of the
                    North American Victorian Studies Association’s Best Book of
                    the Year. She is also co- author of Narrative Theory: Core
                    Concepts and Critical Debates (Ohio State UP, 2012) and
                    co-editor, with Zara Dinnen, of the Edinburgh Companion to
                    Contemporary Narrative Theories (Edinburgh University Press,
                    2018). She leads the research team and sets goals and
                    priorities for the technical evolution of RLV.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Leigh Bonds</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Project Consultant, Assistant Professor and Digital
                      Humanities Librarian, The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Bonds, who holds a Ph.D. in nineteenth-century British
                    literature and has served as liaison between the research
                    team and the technical staff for “Reading Like a Victorian”
                    since 2016. In addition to consulting with faculty and
                    graduate students on computational research and digital
                    projects, she is a collaborator on the Henry Luce
                    Foundation-funded American Religious Sounds Project with
                    co-PIs Isaac Weiner (OSU) and Amy DeRogatis (MSU) and
                    Reclaiming Our Histories with Mytheli Screenivas (OSU).
                    Bonds serves as the chair-elect of the BTAA DH Committee,
                    as the co-coordinator the Digital Library Federation’s (DLF)
                    Digital Scholarship Working Group, and as a member of
                    the Association of Research Libraries’ Digital Scholarship
                    Institute’s Advisory Group. Her dual expertise in Victorian
                    studies and digital programmers and explain the digital
                    structure of the site to the research team.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Colleen Morrissey</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      First Project Manager, Website Editor and Researcher for Reading Like a Victorian, Lecturer and Academic Program Associate, Department of English, The University of Kansas
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Her research explores literature and culture, women writers, digital media, and media history. She was awarded an O. Henry Prize in 2014 and has been a Best American Short Stories Notable. She collaborated with Warhol to create and populate the original WordPress site for Reading Like a Victorian at victorianserialnovels.org
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Drew Sweet</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Second Project Manager, Website Editor and Researcher for Reading Like a Victorian, Ph.D. in English, The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    As a graduate student at OSU, he focused on narrative and seriality across media, including film, television, video games, and the novel, with emphasis on how we may understand both old and new ways of creating, distributing, and receiving narrative, especially in serial form. His work on Reading Like a Victorian was supported by a Battelle Engineering, Technology and Human Affairs (BETHA) Endowment grant at OSU.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Lillian Felkner</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Website Editor and Researcher for Reading Like a Victorian, Undergraduate major in English and Spanish, The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    An Honors student specializing in 19th-century literature, Felkner joined the project in 2022. Her work on RLV is supported by a Humanities Collections and Reference Resources grant for “The Part Issue Project” from the National Endowment for the Humanities.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Abhijit Varde</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Liaison to Arts & Sciences Technology Services (ASCTech),
                      Software Engineer, College of Arts & Sciences,
                      The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-9">
                    Varde works as an instructional and user
                    inferface/interaction designer with the
                    Application Development group of ASC Tech.
                    In this capacity he works with faculty, graduate
                    and undergraduate students and staff to develop software
                    and other multimedia instructional materials for the
                    purpose of teaching and learning.
                    He holds a Ph.D. in Arts Education from The Ohio State
                    University with a minor in Anthropology.
                    He received an MFA in Industrial Design from Ohio State
                    and a BFA from the Academy of Art in San Francisco.
                    Varde has published a book of portraits about women
                    from his home state in India and has a number of
                    electronic publications to his credit, including
                    DVD-based Chinese and Japanese multi-year language
                    learning software. He has developed several institutional
                    websites, MOOCs, courseware and the design and development
                    of two language placement tests in Chinese, French, German
                    and Spanish. He has professional experience in graphic
                    design, exhibition planning & design, commercial
                    & documentary photography and software development.
                    In the academy he is well versed in in visual anthropology,
                    information architecture & design.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Alex Kasler</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Web Developer, Arts and Sciences Technology Services,
                      The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Kasler is a web applications developer for ASC Tech,
                    where he began working in 2018, and for the Mathematical
                    Biosciences Institute (MBI), where he has worked since 2014.
                    He received his B.S. in Computer Information Systems
                    with an emphasis in Web Development and Administration
                    from DeVry University in 2008. At ASC Tech he creates
                    and supports custom web applications for researchers in
                    the College of Arts and Sciences. At MBI, he maintains
                    a public-facing website driven by concrete5 CMS and
                    maintains both the interface and the back end of a
                    custom visitor registration system using Python/Django.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Dustin Perzanowski</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Web Developer, Arts and Sciences Technology Services,
                      The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Perzanowski has been a Web Developer for the College of
                    Arts and Sciences at OSU since 2013. Previously he held
                    the same position in the College of Education and Human
                    Ecology at OSU from 2008-2013. He received his B.S. in
                    Computer Science and Engineering at OSU in 2008.
                    For Arts and Sciences Technology, he has maintained web
                    hosting platforms for 200+ Drupal websites, as well as
                    creating and maintaining configuration management for web
                    server infrastructure. He has created two Ruby on Rails
                    applications and maintained 10+ applications in Ruby on
                    Rails, and has migrated Drupal 6 sites to the Drupal 7
                    platform as well as migrating locally hosted Drupal sites
                    to a cloud-hosted vendor.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Jennifer Schnabel</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Liaison to University Library and English Literature
                      database specialist, Assistant Professor/English Librarian,
                      Thompson Library, The Ohio State University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                      Schnabel is the full-time subject- area librarian
                      for English at OSU’s main library. Her research
                      specialty is 19th-century and early 20th-century
                      detective fiction, much of which was serialized
                      in periodicals.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Madeline Ullrich</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Project Assistant for Reading Like a Victorian, Assistant Professor of Women’s and Gender Studies, The University of Oklahoma
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    As an Andrew R. Mellon Digital Humanities Fellow at the University of Rochester, she learned about Reading Like a Victorian while attending OSU’s “Project Narrative” seminar in 2019. Her research explores how the narrative, serial, and aesthetic forms of television construct contemporary female subjects and audiences in relation to feminism, post-feminism, neoliberalism, and “quality” TV.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Yvette Shen</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      User-Experience Consultant
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                      Yvette Shen is an Assistant Professor of Visual
                      Communication Design in the Department of Design
                      at The Ohio State University. Before joining academia,
                      she worked as an interactive designer closely
                      collaborating with people in professional industries
                      such as healthcare, automotive, arts and entertainment,
                      and non-profit organizations. Her current creative and
                      research expertise mainly lies in the field of information
                      design and information visualization. In particularly,
                      how design can help people gain better understanding
                      of complex information and increase their interest
                      in learning more; and how the practice of visualization
                      and user experience may empower people with positive
                      behaviors and emotions. She values the importance of
                      an interdisciplinary problem-solving process of
                      exploring and providing functional, creative, and
                      human-centered design solutions to complex problems.
                      Her creative work has been exhibited internationally,
                      and she has published her design research studies in
                      various peer-reviewed journals. Her interactive
                      and print design work has been awarded by GD USA,
                      Information is Beautiful, AEJMC, Adobe, Creative
                      Quarterly, and Applied Arts magazine.
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Jessica Terekhov</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Research Consultant for Reading Like a Victorian, Assistant Professor of English, Northeastern University London
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    A contributing editor for Troy Bassett’s website, At the Circulating Library: A Database of Victorian Fiction, 1837-1901, she has compiled a bibliography of Victorian novels originally issued in serial parts. Her Princeton dissertation focused on the concept of wit in British Literature from the 18th-century to the Modernist period.
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-tab-panel>

        <q-tab-panel name="advisory">
          <q-scroll-area visible style="height: 85vh;">
            <p class="q-px-md">
              The Advisory Board are all experts in Victorian novels
              and/or seriality, and some of them have deep experience
              with DH projects. The Board members read drafts of grant
              proposals and make suggestions for revision, help disseminate
              updated information about RLV through their international
              networks, and use RLV in their courses and cite it in their
              scholarship when applicable. to be answered by the RLV team’s
              tagging serial installments for searching.
            </p>

            <q-separator inset color="grey-5" />

            <q-list padding>
              <q-item>
                <q-item-section>
                  <q-item-label>Susan Bernstein</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Research Professor of English, Boston University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Co-author of “Reading Numbers by Numbers: Digital Studies
                    and the Victorian Serial Novel,” Victorian Review 3 8.2
                    (2012): 43-68, on using DocuScope to distinguish stylistic
                    features of serial novels from volume-form novels;
                    host of “Serial Reading” blog (2008).
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:sdbern@bu.edu"
                       title="email Susan Bernstein">
                      sdbern@bu.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>John Bowen</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor of Nineteenth-Century Literature,
                      York University, UK
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Authority on Dickens; co-author of Dickens as Professional Author ( Oxford, 2017); author of Other Dickens: Pickwick to Chuzzlewit (Oxford, 2003) and over a dozen articles on Dickens’s novels.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:john.bowen@york.ac.uk"
                       title="email John Bowen">
                      john.bowen@york.ac.uk
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>James Buzard</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor of Literature, Massachusetts
                      Institute of Technology
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Co-creator of “The Serial Experience Project” at MIT HyperStudio; author of two monographs and numerous articles on Victorian fiction and culture.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:jmbuzard@mit.edu"
                       title="email James Buzard">
                      jmbuzard@mit.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Peter Capuano</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Associate Professor of English, The University of
                      Nebraska, Lincoln
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Expert in digital humanities and Victorian literature; author of Changing Hands: Industry, Evolution and the Reconfiguration of the Victorian Body (U. of Michigan Press, 2015), the result of an extended project in digital text mining; currently working on Novel Bodies: Idiomatic Representation and the Development of Fiction, with support from a two-year grant in Digital Textual Studies from the National Humanities Center.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:pcapuano2@unl.edu"
                       title="email Peter Capuano">
                      pcapuano2@unl.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Elana Gomel</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Associate Professor of English and American Studies,
                      Tel Aviv University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Author of books and articles on Victorian fiction, narrative structure, and the relation of temporality and spatiality in fiction.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:egomel@post.tau.ac.il"
                       title="email Elana Gomel">
                      egomel@post.tau.ac.il
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Lauren Goodlad</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor of English, Rutgers University
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Authority on serialization and genre, with emphasis
                    on the novel and serial television. With Sean O’Sullivan,
                    curated “The New Seriality Studies” symposium for
                    Columbia University’s Heyman Center in 2016.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:lauren.goodlad@rutgers.edu"
                       title="email Lauren Goodlad">
                      lauren.goodlad@rutgers.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Jonathan Grossman</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor of English, University of California,
                      Los Angeles
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Author of two books on Victorian novels and
                    simultaneous temporalities, Charles Dickens’s
                    Networks: Public Transport and the Novel (Oxford, 2012)
                    and The Art of Alibi: English Law Courts and the
                    Novel (Johns Hopkins, 2002); co-creator of two DH projects,
                    Digitizing Yellowback Cover Art (searchable collection
                    of cover images from 19th-century novels sold, as were
                    serial part issues, in railway bookstalls) and
                    Cruikshank’s Eye: A 19th-century Artist in the
                    Twinkle of a Digital Archive (in progress).
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:grossman@humnet.ucla.edu"
                       title="email Jonathan Grossman">
                      grossman@humnet.ucla.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Nancy Henry</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor of English, University of Tennessee, Knoxville
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Expert on Victorian novelists whose work was
                    often serialized, including Eliot, Gaskell, and Dickens.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:nhenry3@utk.edu"
                       title="email Nancy Henry">
                      nhenry3@utk.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>John Jordan</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Research Professor, Literature Department;
                      Director of the Dickens Project, University of California,
                      Santa Cruz
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Founding Director of the Dickens Project at UCSC and
                    the annual Dickens Universe which brings together faculty,
                    grad and undergrad students, and general readers of Dickens;
                    authority on Dickens’s serial publication practices.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:picasso@ucsc.edu"
                       title="email John Jordan">
                      picasso@ucsc.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Frank Kelleter</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor and Head of Department of Culture,
                      John F. Kennedy Institute, Freie Universität Berlin
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Director of Popular Seriality: Aesthetics and Practice
                    Research Unit at University of Göttingen and FUB from
                    2010-2016; co-director of “Authorization Practices of
                    Serial Narration,” “The Dynamics of Serial Outbiding,”
                    and “Retrospective Serialization” projects; author of
                    Serial Agencies: The Wire and its Readers (Zero Books, 2014)
                    and editor of Media of Serial Narrative
                    (Ohio State University Press, 2017).
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:frank.kelleter@fu-berlin.de"
                       title="email Frank Kelleter">
                      frank.kelleter@fu-berlin.de
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Teresa Mangum</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor in Gender, Women’s and Sexuality Studies
                      and Director of Obermann Center for Advanced Studies,
                      The University of Iowa
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Author of books and articles on 19th-century British
                    novels and on publicly engaged pedagogy; co-editor of
                    book series, Humanities and Public Life for U. of
                    Iowa Press. Co-founder of Obermann Graduate Institute
                    on Engagement and the Academy, host of Obermann humanities
                    symposia such as “Platforms for Public Scholars.”
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:teresa-mangum@uiowa.edu"
                       title="email Teresa Mangum">
                      teresa-mangum@uiowa.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Helena Michie</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Agnes Cullen Arnold Professor in Humanities,
                      Rice University (Writer of support letter.)
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                    Author of four monographs in Victorian studies,
                    including Victorian Honeymoons: Journeys to the Conjugal
                    (Cambridge, 2006) and (co-authored with Robyn Warhol)
                    Love Among the Archives (Edinburgh UP, 2015) and of
                    many essays on 19th-century fiction, e.g.,
                    “Victorian(ist) Sentences: Synchronic Temporalities”
                    (On Periodization, English Institute 2010).
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:michie@rice.edu"
                       title="Helena Michie">
                      michie@rice.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Carolyn Williams</q-item-label>
                  <q-item-label caption class="text-grey-10">
                    <strong>
                      Professor of English, Rutgers University
                      (Writer of support letter.)
                    </strong>
                  </q-item-label>
                  <q-item-label caption class="text-grey-10">
                     Author of two books and numerous articles on Victorian
                     literature and culture.
                  </q-item-label>
                </q-item-section>

                 <q-item-section side top>
                  <q-item-label caption>
                    <a href="mailto:carolyn.williams@rutgers.edu"
                       title="Carolyn Williams">
                      carolyn.williams@rutgers.edu
                    </a>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      tab: 'about',
    };
  },
  computed: {
    showing: {
      get() {
        return this.showAboutUs;
      },
      set(show) {
        this.toggleAboutUs({ show });
      },
    },
    ...mapState('timeline', [
      'showAboutUs',
    ]),
  },
  methods: {
    ...mapMutations('timeline', [
      'toggleAboutUs',
    ]),
    selectTab(tab) {
      this.tab = tab;
    },
  },
}
</script>

<style lang="scss">
.about-us {
  background-color: transparent;

  h2 {
    font-size: 1.4em;
    font-weight: 700;
    margin: 0 0 6px;
  }

  &__tab-panels {
    .q-tab-panel {
      padding: 0 16px;
    }
  }
}
</style>
